import React, { useEffect, useState } from 'react';

import { Container } from 'src/components/Container';
import { Input } from 'src/components/Input';
import { Glass } from 'src/components/Glass';
import { Table } from 'src/components/Table';
import { Loader } from 'src/components/Loader';
import { Button } from 'src/components/Button';
import CancelTrx from './CancelModal';
import ConfirmTrx from './ConfirmModal';

import request from 'src/request';
import { PUBLIC_URL } from 'src/configs';
import { setAppAlert } from 'src/store/slice/appAlert';
import { useDispatch } from 'src/store/useDispatch';

const PresaleTransactions = () => {
  const [loading, setLoading] = useState(false);
  const [trxToCancel, setTrxToCancel] = useState<any>(null);
  const [trxToConfirm, setTrxToConfirm] = useState<any>(null);
  const [transactions, setTransactions] = useState([]);
  const [receipt, setReceipt] = useState<File | null>(null);
  const [blockchainTrxId, setBlockchainTrxId] = useState('');

  const dispatch = useDispatch();

  const fetchTransactions = () => {
    setLoading(true);

    request
      .get(`${PUBLIC_URL}/presale/client/presale-transaction`)
      .then((data) => {
        setTransactions(data.data.token_setups);
      })
      .catch(() => {
        dispatch(
          setAppAlert({
            message: 'Something went wrong',
            isSuccess: false,
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmTransaction = () => {
    if (!receipt) {
      dispatch(
        setAppAlert({
          message: 'Please upload a receipt before confirming the transaction.',
          isSuccess: false,
        }),
      );
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('blockchain_transaction_id', blockchainTrxId);
    formData.append('transaction_id', trxToConfirm.id);
    formData.append('presale_transaction', receipt);

    request
      .put(`${PUBLIC_URL}/presale/client/presale-transaction`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        dispatch(
          setAppAlert({
            message: 'You have confirmed transaction',
            isSuccess: true,
          }),
        );
        setTimeout(() => {
          fetchTransactions();
        }, 100);
      })
      .catch(() => {
        dispatch(
          setAppAlert({
            message: 'Something went wrong',
            isSuccess: false,
          }),
        );
      })
      .finally(() => {
        setTrxToConfirm(null);
        setLoading(false);
      });
  };

  const cancelTransaction = () => {
    setLoading(true);

    request
      .put(`${PUBLIC_URL}/presale/client/presale-transaction/cancel`, {
        transaction_id: trxToCancel.id,
      })
      .then(() => {
        dispatch(
          setAppAlert({
            message: 'You have cancelled transaction',
            isSuccess: true,
          }),
        );
        setTimeout(() => {
          fetchTransactions();
        }, 100);
      })
      .catch(() => {
        dispatch(
          setAppAlert({
            message: 'Something went wrong',
            isSuccess: false,
          }),
        );
      })
      .finally(() => {
        setTrxToCancel(null);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader overlay />;
  }

  return (
    <>
      <Glass>
        <Container textAlign="center" fontSize="1.75rem" paddingBottom="1.25rem">
          This table shows all types and status of transactions
        </Container>
        <Container padding="1.25rem" border="1px solid #eee" borderRadius="0.6rem">
          <Container display="flex" justifyContent="flex-end" marginBottom="0.6rem">
            <Input placeholder="Enter Search Results" value="" onChange={() => null} />
          </Container>
          <Table
            headers={[
              'Transaction NO',
              'Presail User ID',
              'Status',
              'Amount',
              'Coin',
              'Base Price',
              'Stage',
              'Blockchain',
              'Tokens',
            ]}
            items={transactions}
            columns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
            currentPage={1}
            setCurrentPage={() => null}
            totalPages={1}
            fieldsToShow={[
              'transaction_number',
              'presale_user_id',
              'status',
              'amount',
              'coin',
              'coin_base_price',
              'stage',
              'blockchain',
              'tokens',
            ]}
            actions={(item) => {
              if (item.status === 'pending') {
                return (
                  <Container display="flex" gap="0.5rem">
                    <Button
                      text="Cancel"
                      onClick={(e) => {
                        e.stopPropagation();
                        setTrxToCancel(item);
                      }}
                      styles={{
                        background: '#DC3545',
                      }}
                    />
                    <Button
                      text="Confirm"
                      onClick={(e) => {
                        console.log(item);
                        e.stopPropagation();
                        setTrxToConfirm(item);
                      }}
                    />
                  </Container>
                );
              }
            }}
          />
          <Container display="grid" gridTemplateColumns="repeat(8, 1fr)" gap="0px"></Container>
        </Container>
      </Glass>
      {trxToCancel && (
        <CancelTrx
          onCancel={cancelTransaction}
          onClose={() => setTrxToCancel(null)}
          trx={trxToCancel}
        />
      )}
      {trxToConfirm && (
        <ConfirmTrx
          onConfirm={confirmTransaction}
          onClose={() => setTrxToConfirm(null)}
          receipt={receipt}
          blockchainTrxId={blockchainTrxId}
          setBlockchainTrxId={setBlockchainTrxId}
          setReceipt={setReceipt}
          trx={trxToConfirm}
        />
      )}
    </>
  );
};

export default PresaleTransactions;
