import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PUBLIC_URL } from 'src/configs';
import request from 'src/request';
import { setAppAlert } from './appAlert';

interface IPresaleInfo {
  minimum_deposit: {
    amount: number;
    currency: string;
  };
  _id: string;
  total_tokens: number;
  purchased_tokens: number;
  queued_tokens: number;
  base_price: {
    $numberDecimal: string;
  };
  current_stage: number;
  supported_payment_options: [
    {
      supported_coins: string[];
      _id: string;
      blockchain: string;
      deposit_address: string;
    },
    {
      supported_coins: string[];
      _id: string;
      blockchain: string;
      deposit_address: string;
    },
    {
      supported_coins: string[];
      _id: string;
      blockchain: string;
      deposit_address: string;
    },
    {
      supported_coins: string[];
      _id: string;
      blockchain: string;
      deposit_address: string;
    },
  ];
  base_price_for_each_stage: [
    {
      _id: string;
      stage: number;
      triggering_amount: number;
      price_increment: number;
    },
    {
      _id: string;
      stage: number;
      triggering_amount: number;
      price_increment: number;
    },
    {
      _id: string;
      stage: number;
      triggering_amount: number;
      price_increment: number;
    },
    {
      _id: string;
      stage: number;
      triggering_amount: number;
      price_increment: number;
    },
    {
      _id: string;
      stage: number;
      triggering_amount: number;
      price_increment: number;
    },
  ];
  discounts: [
    {
      _id: string;
      minimum_buy: number;
      discount: number;
    },
    {
      _id: string;
      minimum_buy: number;
      discount: number;
    },
    {
      _id: string;
      minimum_buy: number;
      discount: number;
    },
    {
      _id: string;
      minimum_buy: number;
      discount: number;
    },
  ];
  created_at: string;
  updated_at: string;
  __v: number;
}

interface PresaleState {
  presale: IPresaleInfo | null;
  loading: boolean;
}

const initialState: PresaleState = {
  presale: null,
  loading: false,
};

export const fetchPresale = createAsyncThunk(
  'notifications/fetchPresale',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await request.get(`${PUBLIC_URL}/presale/client/presale-info`);
      return response.data.token_setups[0];
    } catch (err) {
      // @ts-expect-error TODO: Specify type
      if (!err.response) {
        throw err;
      }

      // @ts-expect-error TODO: Specify type
      dispatch(setAppAlert({ message: err.response.data.error ?? 'Something went wrong' }));
      // @ts-expect-error TODO: Specify type
      return rejectWithValue(err.response.data);
    }
  },
);

const presaleSlice = createSlice({
  name: 'presale',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPresale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPresale.fulfilled, (state, action) => {
        state.presale = action.payload;
        state.loading = false;
      })
      .addCase(fetchPresale.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default presaleSlice.reducer;
