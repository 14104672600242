import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTE_BUY_TOKEN } from 'src/routes';

import { Container } from 'src/components/Container';
import { Button } from 'src/components/Button';
import ProgressBar from './ProgressSection';
import GradientedArea from './GradientedArea';

import { formatNumberWithSpaces } from 'src/constants';

import { StyledRoundSection } from '../styled';

const RoundSection = ({ presaleInfo, isEmailVerified }) => {
  const navigate = useNavigate();
  const soldPercentage = (presaleInfo.purchased_tokens / presaleInfo.total_tokens) * 100;
  const orderedPercentage = (presaleInfo.queued_tokens / presaleInfo.total_tokens) * 100;

  const basePrice = Number(presaleInfo.base_price.$numberDecimal);
  const currentStage = presaleInfo.current_stage;
  const nextStage = currentStage + 1;
  const nextStagePriceIncrement =
    presaleInfo.base_price_for_each_stage[nextStage - 1].price_increment;

  const nextStagePrice = nextStagePriceIncrement * basePrice + basePrice;

  return (
    <StyledRoundSection>
      <Container fontSize="1.5rem" paddingBottom="1.25rem" textAlign="center">
        Stage {presaleInfo.current_stage}
      </Container>
      <Container display="flex" justifyContent="space-between" alignItems="center">
        <Container fontSize="1.25rem" marginBottom="0.6rem">
          Token remaining in stage:
        </Container>
        <Container>
          {formatNumberWithSpaces(
            presaleInfo.total_tokens - presaleInfo.purchased_tokens - presaleInfo.queued_tokens,
          )}
        </Container>
      </Container>
      <GradientedArea padding="1rem 0 0">
        <Container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid #ccc"
          gap="1rem"
          borderRadius="1.25rem"
          padding="1rem"
        >
          <ProgressBar percentage={soldPercentage} />
          <Container display="flex" gap="0.3rem" fontSize="1rem" fontWeight={600}>
            <span>SOLD</span>
            <span>{soldPercentage}%</span>
          </Container>
        </Container>
      </GradientedArea>
      <GradientedArea padding="1rem 0 0">
        <Container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid #ccc"
          gap="1rem"
          borderRadius="1.25rem"
          padding="1rem"
        >
          <ProgressBar percentage={orderedPercentage} />
          <Container display="flex" gap="0.3rem" fontSize="1rem" fontWeight={600}>
            <span>ORDERED</span>
            <span>{orderedPercentage}%</span>
          </Container>
        </Container>
      </GradientedArea>
      <GradientedArea>
        <Container display="flex" justifyContent="space-between">
          <Container>$BXT price in current stage:</Container>
          <Container>{presaleInfo.base_price.$numberDecimal} USD</Container>
        </Container>
        <Container display="flex" justifyContent="space-between">
          <Container>The next stage price:</Container>
          <Container>{nextStagePrice} USD</Container>
        </Container>
      </GradientedArea>
      <GradientedArea>
        <Container display="flex" justifyContent="space-between">
          <Container>Tokens Sold In Total:</Container>
          <Container>
            {formatNumberWithSpaces(
              presaleInfo.purchased_tokens * presaleInfo.base_price.$numberDecimal,
            )}
          </Container>
        </Container>
      </GradientedArea>
      <Container display="flex" justifyContent="center">
        <Button
          text="Buy Token Now"
          disabled={!isEmailVerified}
          onClick={() => navigate(ROUTE_BUY_TOKEN)}
        />
      </Container>
    </StyledRoundSection>
  );
};

export default RoundSection;
