import React from 'react';

import { Button } from 'src/components/Button';
import { Container } from 'src/components/Container';
import { Modal } from 'src/components/Modal';

interface CancelTrxProps {
  onCancel: () => void;
  onClose: () => void;
  trx: any;
}

const CancelTrx: React.FC<CancelTrxProps> = ({ onCancel, onClose, trx }) => {
  return (
    <Modal isOpen onClose={onClose}>
      <Container textAlign="center" fontWeight={600} fontSize="1.5rem">
        Are you sure you want to cancel transaction NO {trx.transaction_number}?
      </Container>
      <Container display="flex" justifyContent="space-around" alignItems="center" paddingTop="3rem">
        <Button
          text="Cancel transaction"
          onClick={onCancel}
          styles={{
            background: '#DC3545',
          }}
        />
        <Button type="outlined" text="Close" onClick={onClose} />
      </Container>
    </Modal>
  );
};

export default CancelTrx;
