import React from 'react';

import { Container } from 'src/components/Container';
import GradientedArea from './GradientedArea';

import { getSign } from 'src/constants';

import { StyledRoundSection } from '../styled';

const TokenBalance = () => {
  const token = localStorage?.getItem('access');
  const sign = getSign();

  return (
    <StyledRoundSection>
      <Container position="relative">
        {!token && (
          <Container
            position="absolute"
            zIndex="9999"
            top="0"
            left="0"
            borderRadius="1.25rem"
            width="100%"
            height="100%"
            backgroundColor="rgba(255, 255, 255, 0.7)"
            backdropFilter="blur(4px)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="0.25rem"
          >
            <span>
              <a href={sign}>Login</a>
            </span>
            <span>using password</span>
          </Container>
        )}
        <Container fontSize="1.5rem" paddingBottom="1.25rem" textAlign="center">
          Token Balance
        </Container>
        <Container marginBottom="0.6rem">0 $BXT</Container>
        <GradientedArea>
          <Container display="flex" flexDirection="column" justifyContent="space-between">
            <Container>Balance in other currencies:</Container>
            <Container display="flex" justifyContent="space-between">
              <span>USD</span>
              <span>~</span>
            </Container>
            <Container display="flex" justifyContent="space-between">
              <span>BTC</span>
              <span>~</span>
            </Container>
            <Container display="flex" justifyContent="space-between">
              <span>ETH</span>
              <span>~</span>
            </Container>
          </Container>
        </GradientedArea>
      </Container>
    </StyledRoundSection>
  );
};

export default TokenBalance;
