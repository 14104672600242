import React, { useEffect, useState } from 'react';
import { BiCopy } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';

import { Container } from 'src/components/Container';
import { Glass } from 'src/components/Glass';
import { Button } from 'src/components/Button';
import { Loader } from 'src/components/Loader';
import { AlertCopy } from 'src/components/Table/components/AlertCopy';

import RoundSection from './components/RoundSection';
import TokenBalance from './components/TokenBalance';
import WalletAddressModal from '../BuyTokens/components/WalletAddressModal';
import Badge from 'src/components/StatusBadge/Badge';

import { ROUTE_EMAIL_VERIFICATION } from 'src/routes';
import { fetchPresale } from 'src/store/slice/presale';
import { getSign, handleCopy, shortenString } from 'src/constants';
import { PUBLIC_URL } from 'src/configs';
import { setAppAlert } from 'src/store/slice/appAlert';
import request from 'src/request';

const Presale = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [receivingWallet, setReceivingWallet] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage?.getItem('access');
  const sign = getSign();

  const {
    user: { email_verified },
  } = useSelector(({ user }) => user);
  const { presale, loading } = useSelector(({ presale }) => presale);

  const getPresaleUser = () => {
    setIsLoading(true);

    request
      .get(`${PUBLIC_URL}/presale/client/presale-user`)
      .then((data) => {
        setReceivingWallet(data.data.presale_user.receiving_wallet);
      })
      .catch(() => {
        dispatch(
          setAppAlert({
            message: 'Something went wrong',
            isSuccess: false,
          }),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!presale) {
      dispatch(fetchPresale());
    }

    if (token) {
      getPresaleUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || isLoading) {
    return <Loader overlay />;
  }

  return (
    <>
      <Glass>
        <Container fontSize="1.75rem" textAlign="center" paddingBottom="1.25rem">
          Presale
        </Container>
        <Container display="flex" justifyContent="center" gap="2rem" flexWrap="wrap">
          {presale && <RoundSection presaleInfo={presale} isEmailVerified={email_verified} />}
          <Container>
            <TokenBalance />
            <Container
              border="1px solid #ccc"
              borderRadius="1.25rem"
              padding="1.25rem"
              marginTop="1.25rem"
              position="relative"
            >
              {!token && (
                <Container
                  position="absolute"
                  zIndex="9999"
                  top="0"
                  left="0"
                  borderRadius="1.25rem"
                  width="calc(100% - 2.5rem)"
                  marginLeft="1.25rem"
                  height="100%"
                  backgroundColor="rgba(255, 255, 255, 0.7)"
                  backdropFilter="blur(4px)"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap="0.25rem"
                >
                  <span>
                    <a href={sign}>Login</a>
                  </span>
                  <span>using password</span>
                </Container>
              )}
              <Container
                display="flex"
                gap="5rem"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="2rem"
              >
                <Container fontWeight={600}>ACCOUNT STATUS</Container>
                <Container padding="0.2rem 0" borderRadius="0.25rem">
                  {email_verified ? (
                    <Badge value="VERIFIED" />
                  ) : (
                    <Button
                      type="outlined"
                      text="Verify Email"
                      onClick={() => navigate(ROUTE_EMAIL_VERIFICATION)}
                    />
                  )}
                </Container>
              </Container>
              <Container
                display="flex"
                gap="5rem"
                alignItems="center"
                justifyContent="space-between"
              >
                <Container fontWeight={600}>RECEIVING WALLET</Container>
                <Container display="flex" alignItems="center" gap="1rem">
                  {receivingWallet ? (
                    <Container display="flex" alignItems="center" gap="1rem">
                      <Container>{shortenString(receivingWallet)}</Container>
                      <Container onClick={() => handleCopy(receivingWallet)} cursor="pointer">
                        <BiCopy />
                      </Container>
                    </Container>
                  ) : (
                    <Button
                      text="Add Wallet"
                      disabled={!email_verified}
                      onClick={() => setOpen(true)}
                    />
                  )}
                </Container>
              </Container>
              <AlertCopy />
            </Container>
          </Container>
        </Container>
      </Glass>
      <WalletAddressModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default Presale;
