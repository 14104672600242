import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';
import { fetchCurrencyPairs } from 'src/store/slice/currencyPairs';
import { fetchTickers } from 'src/store/slice/tickers';
import { fetchNotificationsCount } from 'src/store/slice/notificationsCount';

import HelpSection from './components/HelpSection';
import Join from './components/Join';
import Main from './components/Main';
import Portfolio from './components/Portfolio';
import Table from './components/Table';
import StartToday from './components/Start';
import OurSocials from './components/OurSocials';
import Footer from './components/Footer';

import './index.css';

const Home = () => {
  const token = localStorage.getItem('access');

  const dispatch = useDispatch();

  const { pairs } = useSelector(({ pairs }) => pairs);
  const { tickers } = useSelector(({ tickers }) => tickers);
  const { user } = useSelector(({ user }) => user);

  useEffect(() => {
    if (!pairs.length) {
      dispatch(fetchCurrencyPairs());
    }

    if (!tickers.length) {
      dispatch(fetchTickers());
    }

    if (token) {
      dispatch(fetchNotificationsCount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Main />
      <div>
        <OurSocials />
        <Table pairData={pairs} tableData={tickers} />
        <Portfolio />
        <Join />
        <HelpSection />
        <StartToday user={user} />
      </div>
      <Footer />
    </>
  );
};

export default Home;
